@font-face {
    font-family: 'Twemoji Country Flags';
    unicode-range: U+1F1E6-1F1FF, U+1F3F4, U+E0062-E0063, U+E0065, U+E0067, U+E006C, U+E006E, U+E0073-E0074, U+E0077, U+E007F;
    src: url('https://cdn.jsdelivr.net/npm/country-flag-emoji-polyfill@0.1/dist/TwemojiCountryFlags.woff2') format('woff2');
  }

  .flag-enable {
    font-family: 'Twemoji Country Flags' ,"Montserrat", "Helvetica Neue", Arial, sans-serif;
  }


.w3-flat-turquoise
{color:#fff;background-color:#1abc9c}
.w3-flat-emerald
{color:#fff;background-color:#2ecc71}
.w3-flat-peter-river
{color:#fff;background-color:#3498db}
.w3-flat-amethyst
{color:#fff;background-color:#9b59b6}
.w3-flat-wet-asphalt
{color:#fff;background-color:#34495e}
.w3-flat-green-sea
{color:#fff;background-color:#16a085}
.w3-flat-nephritis
{color:#fff;background-color:#27ae60}
.w3-flat-belize-hole
{color:#fff;background-color:#2980b9}
.w3-flat-wisteria
{color:#fff;background-color:#8e44ad}
.w3-flat-wisteria-outline
{border: #8e44ad solid 1px;}
.scope-color
{color:#fff;background-color:#8e44ad}
.scope-color-outline
{border: #8e44ad solid 1px;}
.w3-flat-midnight-blue
{color:#fff;background-color:#2c3e50}
.w3-flat-sun-flower
{color:#fff;background-color:#f1c40f}
.w3-flat-carrot
{color:#fff;background-color:#e67e22}
.w3-flat-alizarin
{color:#fff;background-color:#e74c3c}
.w3-flat-clouds
{color:#000;background-color:#ecf0f1}
.w3-flat-concrete
{color:#fff;background-color:#95a5a6}
.w3-flat-orange
{color:#fff;background-color:#f39c12}
.w3-flat-pumpkin
{color:#fff;background-color:#d35400}
.w3-flat-pumpkin-outline
{border: #d35400 solid 1px;}
.w3-flat-pomegranate
{color:#fff;background-color:#c0392b}
.w3-flat-silver
{color:#000;background-color:#bdc3c7}
.w3-flat-asbestos
{color:#fff;background-color:#7f8c8d}

.cursor-pointer
{cursor:pointer}

.btn-cud
{
 font-size:12px;
 color:#0008;
 cursor:pointer;
 margin-right: 32px
 }

.custom-checkbox .custom-control-label
{
	font-size:16px;
}

.hover-over-darken:hover
{filter:brightness(0.9); transition: all 300ms ease 0s;}

.hover-over-other-darken:hover .hover-over-other-darken-target:not(:hover)
{filter:brightness(0.9); transition: all 300ms ease 0s;}

.hover-over-ligthen:hover
{filter:brightness(1.1) !important; transition: all 300ms ease 0s;}

.fade-in{
	animation: slide-up 0.3s ease;
}

.main-panel > .content {
    min-height: calc(100vh - 158px) !important;
}

.navbar .navbar-toggler {
     width: auto; 
     height: auto;
}

.form-control:read-only{
	background-color: #FFF;
	cursor:default;
}

.web-summary .form-control:read-only{
	background-color: #FFF !important;
    border : 0;
	cursor:default;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.react-datepicker-wrapper{
	border : 0 !important;
    width: 100%;
}

.react-datepicker__month-read-view{
        margin-right: 18px;
}

.react-datepicker__navigation--years-upcoming::before{
    content: "▲";
}
.react-datepicker__navigation--years-upcoming{
    line-height: 32px;
    text-indent:0 !important;
}

.react-datepicker__navigation--years-previous::before{
    content: "▼";
}
.react-datepicker__navigation--years-previous{
    line-height: 32px;
    text-indent:0 !important;
}


.react-datepicker-wrapper input{
    height: 40px;
}

.claim-submission-form .react-datepicker-wrapper input{
    height: 32px;
}

.portal-layout .react-datepicker-wrapper,
.mp-layout  .react-datepicker-wrapper
{
	border : 0 !important;
    height : 18px;
}

.portal-layout .react-date-picker__button,
.mp-layout  .react-date-picker__button
{
    padding: 0px 6px;
    background-color: #0000 !important;
}

.mp-layout .navbar-default {
    background-color: #168CB7;
    border-color: #E7E7E7;
    font-weight: 350;
}

.mp-layout .navbar-brand {
    font-size: 18px;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    color: #fff;
}
.mp-layout {
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
}

.react-date-picker__button {
    background-color: #FFF !important;
}

.mp-layout button,
.mp-layout .bg-info
{
    background-color: #168CB7 !important;
    border-color: #E7E7E7;
    color:#FFF !important;
}
.mp-layout button:hover
{
    background-color: #168cb785 !important;
    border-color: #E7E7E7;
    color:#FFF !important;
}
.mp-layout button:focus,
.mp-layout button:active
{
    background-color: #168cb75d !important;
    border-color: #E7E7E7;
    color:#FFF !important;
}

.trackedClaimDetails input{
    font-size :2em;
    margin-bottom: 16px;
}

label {
    display: inline-block;
    margin-bottom: 1px;
}

.dnd-item-tb::before{
	content: "└";
	font-size : 24px;
	position: absolute;
    left: -24px;
	top: -4px;
	color:#888888;
}

@keyframes slide-up {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.web-layout .form-control {
    border-color: #888;

}

.web-layout .nav-link {
    color: #0250aa !important;
}

.required-field::after {
    content: "*";
    color: red;
}

label, input, h3 {
    color:#000 !important;
}

@media (min-width: 576px) {
    .card-columns {
        column-count: 2;
    }
}

@media (min-width: 768px) {
    .card-columns {
        column-count: 2;
    }
}

@media (min-width: 992px) {
    .card-columns {
        column-count: 2;
    }
}

@media (min-width: 1200px) {
    .card-columns {
        column-count: 2;
    }
}