.min-sidebar .sidebar {
	width :0px;
	overflow : hidden;
	transition: all 300ms ease 0s; 
}

.min-sidebar .nav-link {
	margin-left : 0 !important;
}

.min-sidebar .nav-link p{
	color : #FFF0 !important;
	transition: all 300ms ease 0s; 
}

.min-sidebar .main-panel {
	width : calc(100% - 0px);
}

